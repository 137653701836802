import React, {useEffect} from 'react';
import {Button, useLocalStorage, useSnackBar, Variant} from "@jetam/core";
import {API, fetchParams, LOCAL_STORAGE_KEY} from "../../config/config";
import {useDownloadFetch} from "@jetam/core/src/hooks";
import PropTypes from "prop-types";

const ExportContractButton = ({ onSuccess }) => {

    const [{token}] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const {data, loading, doFetch, error} = useDownloadFetch();
    const addNotification = useSnackBar();

    useEffect(() => {
        if (data) {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement("a");
            a.href = url;
            a.download = data.fileName;
            a.click();
            addNotification("Fichier téléchargé avec succès.", Variant.SUCCESS)
            onSuccess();
        }
    }, [data])

    useEffect(() => {
        if (error) addNotification(error.message, Variant.DANGER)
    }, [error])

    const handleNewExport = () => doFetch(`${API.ENDPOINT}export/contract/last`, fetchParams("GET", token))

    return  <Button onClick={handleNewExport} disabled={loading}>Nouvelle exportation</Button>;
};

ExportContractButton.propTypes = {
    onSuccess: PropTypes.func.isRequired
};

export default ExportContractButton;
