export const ENV = {
    DEV: "Development",
    PROD: "Production"
}

export const APP = {
    ENV: ENV.PROD,
}

export const API = {
    ENDPOINT: `${APP.ENV === ENV.DEV ? "http://localhost:10190" : "https://admin.api.k-rixe.com"}/api/v1/admin/`,
}

export const LOCAL_STORAGE_KEY = {
    TOKEN: 'krixe-admin-user'
}

export const fetchParams = (method, token = undefined, body = undefined) => {
    if (!token) {
        return {
            method,
            headers: new Headers({'content-type': 'application/json'})
        }
    }

    return body ? {
        method,
        headers: new Headers({
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body
    } : {
        method,
        headers: new Headers({
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    }
}