import React, {useEffect} from 'react';
import {
    Loader,
    ThemeContainer,
    ThemeStore,
    useApiFetch,
    useLocalStorage,
    useSnackBar,
    Variant
} from "@jetam/core";
import {API, LOCAL_STORAGE_KEY} from "../../config/config";
import {useSetRecoilState} from "recoil";
import {userAtom} from "../recoil/user.state";
import styled from "styled-components";
import TopBarCustomized from "../components/TopBarCustomized";
import Router from "./Router";

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
`

function PreLoader(props) {

    const [token] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const {data, loading, doFetch, error} = useApiFetch();
    const setUserAtom = useSetRecoilState(userAtom);
    const addNotification = useSnackBar();

    useEffect(() => {
        if (token)
            doFetch(API.ENDPOINT + "user", {
                method: 'GET',
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + token.token
                })
            })
    }, []);

    useEffect(() => {
        if (data)
            setUserAtom(data)
    }, [data])

    useEffect(() => {
        if (error) addNotification(error.message, Variant.DANGER)
    }, [error])

    if (data) return <>
        <TopBarCustomized/>
        <Router/>
    </>;

    return <ThemeStore>
        <ThemeContainer>
            <Container>
                <Loader text={"Préchargement des données ..."}/>
            </Container>
        </ThemeContainer>
    </ThemeStore>;
}

export default PreLoader;