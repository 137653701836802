import {useState} from 'react';
import {Alert, Button, Input, Loader, postFetch, useLocalStorage, Variant} from "@jetam/core";
import {Eye, EyeSlash, Lock, User} from "styled-icons/fa-solid";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {API, LOCAL_STORAGE_KEY} from "../../config/config";
import {useSetRecoilState} from "recoil";
import {authAtom} from "../recoil/auth.state";

const UserIcon = styled(User)`
  width: 1.1rem;
  height: 1.1rem;
`
const PasswordIcon = styled(Lock)`
  width: 1.1rem;
  height: 1.1rem;
`

const ShowPasswordIcon = styled(Eye)`
  width: 1.1rem;
  height: 1.1rem;
`

const HideIcon = styled(EyeSlash)`
  width: 1.1rem;
  height: 1.1rem;
`

const Form = styled.form`
  max-width: 420px;
  margin: auto;
`

const validationSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
})

const LoginForm = () => {
    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const setAuthAtom = useSetRecoilState(authAtom);

    const onSubmit = async data => {
        setError(undefined)
        setLoading(true)
        postFetch(`${API.ENDPOINT}login`, data)
            .then(r => {
                setToken(r)
                setAuthAtom({
                    loggingIn: true
                })
            })
            .catch(error => setError("Invalid Credentials"))
            .finally(() => setLoading(false))
    }

    return <Form onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert variant={Variant.DANGER}>{error}</Alert>}
        <Input name={"username"}
               placeholder={"Username"}
               icon={<UserIcon/>}
               ref={register}
               error={errors.username?.message}/>
        <Input name={"password"}
               placeholder={"Password"}
               type={"password"}
               icon={<PasswordIcon/>}
               hidePasswordIcon={<HideIcon/>}
               showPasswordIcon={<ShowPasswordIcon/>}
               error={errors.password?.message}
               ref={register}/>
        <Button type={"submit"} block>Se connecter</Button>

        {loading && <Loader/>}
    </Form>;
}

export default LoginForm;
