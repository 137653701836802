import React from 'react';
import {Route, Switch} from "react-router-dom";
import WeeklyCampaignList from "../../mailchimp/layout/WeeklyCampaignList";
import {Container} from "@jetam/core";
import styled from "styled-components";
import ExportContractList from "../../export/layout/ExportContractList";

const Main = styled(Container)`
  margin-top: 40px;
`

const Router = props =>
    <Main>
        <Switch>
            <Route path={"/mailchimp/weekly"} component={WeeklyCampaignList}/>
            <Route path={"/export/contract"} component={ExportContractList}/>
        </Switch>
    </Main>;

Router.propTypes = {};

export default Router;
