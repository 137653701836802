import {atom, selector} from "recoil";

export const authAtom = atom({
    key: 'authAtom',
    default: {
        loggingIn: false,
    }
})

export const loggingSelector = selector({
    key: 'loggingSelector',
    get: ({get}) => get(authAtom).loggingIn
})
