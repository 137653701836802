import styled, {createGlobalStyle} from "styled-components"
import {
    Device,
    Flex,
    HyperLink,
    Logo,
    space,
    ThemeContainer,
    ThemeStore,
    ThemeSwitcher,
    Variant,
    Whitespace
} from "@jetam/core";
import LoginForm from "../components/LoginForm";
import {ColorSwatch} from "styled-icons/heroicons-outline"

const Container = styled.main`
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100vw;
  height: 100vh;

  @media ${Device.LAPTOP} {
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
  }
`

const Content = styled.div`
  display: grid;
  padding: ${space(4)};
  justify-items: center;
  align-items: center;

  @media ${Device.LAPTOP} {
	background-color: ${props => props.theme.color.normal};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
`

const LoginContent = styled.div`
  display: grid;
  grid-gap: ${space(3)};
  justify-items: center;
`

const LoginCard = styled.div`
  background-color: ${props => props.theme.color.normal};
  padding: ${space(4)};
  border-radius: ${props => !props.withFooter && props.theme.variables.borderRadius};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 100%;

  @media ${Device.TABLET} {
  }

  @media ${Device.LAPTOP} {
	padding: ${space(1)};
	background-color: transparent;
	box-shadow: none;

	&:hover {
	  box-shadow: none;
	}
  }

  @media ${Device.LAPTOP_L} {
  }
`

const ThemeSwitcherRow = styled(Flex)`
  padding: ${space(3)};
  align-items: flex-start;

  > div {
	background-color: ${props => props.theme.color.normal};
	height: 20px;
	width: 20px;
  }
`

const Empty = styled(Whitespace)`
  @media ${Device.LAPTOP} {
	display: none;
  }
`

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;500;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

  body {
	background-image: url("https://k-rixe.com/wp-content/uploads/2020/09/Photo-1-20.09.06.png");
	background-position: center;
	background-origin: border-box;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	font-family: Roboto, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
	font-family: Raleway, sans-serif;
  }
`

const LogoRow = styled(Logo)`
  width: 40%;
  height: auto;
  margin-bottom: ${space(4)};
`

const App = styled.h1`
  color: ${props => props.theme.invertColor.normal};
  font-size: 2rem;
  font-weight: 100;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: ${space(2)};

  > span {
	color: ${props => props.theme.scheme[Variant.PRIMARY].normal};
  }
`

const LightColorSwatch = styled(ColorSwatch)`
  color: ${props => props.theme.scheme.dark.normal};

  > svg {
	width: 48px;
	height: 48px;
  }
`

const DarkColorSwatch = styled(ColorSwatch)`
  color: ${props => props.theme.scheme.light.normal};

  > svg {
	width: 48px;
	height: 48px;
  }
`

const Login = props => <ThemeStore>
    <ThemeContainer>
        <GlobalStyle/>
        <Container>
            <ThemeSwitcherRow>
                <ThemeSwitcher lightIcon={<LightColorSwatch/>} darkIcon={<DarkColorSwatch/>}/>
            </ThemeSwitcherRow>
            <Content>
                <LoginContent>
                    <Flex justifyItems={"center"} align={"center"}>
                        <LogoRow src={"https://k-rixe.com/wp-content/uploads/2020/09/Logo-K-Rixe-signature-email.png"}/>
                    </Flex>
                    <LoginCard>
                        <App>Administration</App>
                        <LoginForm/>
                    </LoginCard>
                    <HyperLink href={"#foo"}>J'ai oublié mon mot de passe</HyperLink>
                </LoginContent>
            </Content>
            <Empty level={1}/>
        </Container>
    </ThemeContainer>
</ThemeStore>;

Login.propTypes = {};

export default Login;
