import React from 'react';
import {useSetRecoilState} from "recoil";
import {authAtom} from "../recoil/auth.state";
import {useHistory} from "react-router-dom";
import {LOCAL_STORAGE_KEY} from "../../config/config";

const Logout = () => {

    const setAuthAtom = useSetRecoilState(authAtom);
    const history = useHistory();

    localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);

    setAuthAtom({
        loggingIn: false
    })

    history.push("/login");
    return <div/>;
};

export default Logout;
