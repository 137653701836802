import {useEffect} from 'react';
import {Route, Switch, useHistory} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {authAtom, loggingSelector} from "./recoil/auth.state";
import Login from "./layout/Login";
import {PrivateRoute} from "./PrivateRoute";
import {useLocalStorage} from "@jetam/core";
import jwt_decode from "jwt-decode";
import Logout from "./layout/Logout";
import {LOCAL_STORAGE_KEY} from "../config/config";
import Main from "../main/layout/Main";

const Router = () => {
    const [token] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const setAuthAtom = useSetRecoilState(authAtom);
    const loggingIn = useRecoilValue(loggingSelector);
    const history = useHistory();

    useEffect(() => {
        if (token) {
            const {exp} = jwt_decode(token.token)
            const loggingIn = Date.now() < exp * 1000;
            setAuthAtom({
                loggingIn
            })
            if (!loggingIn) localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
        }
    }, [setAuthAtom, token]);

    useEffect(() => {
        if (loggingIn) history.push("/")
    }, [history, loggingIn]);

    return <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/logout" component={Logout}/>
        <PrivateRoute authenticated={loggingIn} path="/" component={Main}/>
        {/*<Route exact path="/password-recovery" component={PasswordRecovery}/>*/}
        {/*<Route exact path="/unauthorized" component={Unauthorized}/>*/}
        {/*<Route component={NotFound}/>*/}
    </Switch>;
};

export default Router;