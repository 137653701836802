import React from 'react';
import PropTypes from 'prop-types';
import {Menu, MenuItem, Variant} from "@jetam/core";
import {PageExportCsv} from "styled-icons/foundation";

const ExportMenu = ({handleRouteCallback}) => {
    return <Menu variant={Variant.PRIMARY} title={"Exports"}
                 items={[
                     <MenuItem label={"Contrats"}
                               icon={<PageExportCsv/>}
                               onClick={() => handleRouteCallback("/export/contract")}/>,
                 ]}/>;
};

ExportMenu.propTypes = {
    handleRouteCallback: PropTypes.func.isRequired
};

export default ExportMenu;
