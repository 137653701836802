import React, {useEffect, useState} from 'react';
import ConfirmModal from "../components/ConfirmModal";
import {
    Button, Card,
    CellHead,
    Flex, GrowElement,
    H1,
    Loader,
    Pagination,
    RowHead,
    Section,
    Table,
    useApiFetch,
    useLocalStorage,
    useSnackBar,
    Variant
} from "@jetam/core";
import {API, fetchParams, LOCAL_STORAGE_KEY} from "../../config/config";
import WeeklyCampaignListItem from "../components/WeeklyCampaignListItem";

const WeeklyCampaignList = props => {

    const [modalIsOpened, setModalIsOpened] = useState(false);
    const [{token}] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const {data, loading, doFetch, error} = useApiFetch();
    const [pagination, setPagination] = useState({
        page: 0,
        size: 10,
        totalElements: 0
    });
    const addNotification = useSnackBar();

    useEffect(() => {
        if (data) {
            setPagination({
                totalElements: data.totalElements,
                page: data.number,
                size: data.size,
            })
        }
    }, [data])

    useEffect(() => {
        if (error) addNotification(error.message, Variant.DANGER)
    }, [error])

    const handlePagination = page =>
        doFetch(`${API.ENDPOINT}mailchimp/weekly?page=${page.currentPage - 1}&size=${page.pageLimit}`, fetchParams("GET", token));

    const handleOnClose = e => {
        setModalIsOpened(false)
        if (e) handlePagination({
            currentPage: pagination.page + 1,
            pageLimit: pagination.size,
        })
    }

    return <Section>
        {modalIsOpened && <ConfirmModal isOpened={modalIsOpened} onClose={handleOnClose}/>}
        <Flex>
            <GrowElement level={1}>
                <H1>Dernières campagnes lancées</H1>
            </GrowElement>
            <Button onClick={() => setModalIsOpened(true)}>Nouvelle campagne</Button>
        </Flex>

        {!data?.content && <Card><p>Data not found</p></Card>}
        {loading && <Loader/>}
        {data?.content && !loading && <Table>
            <thead>
            <RowHead>
                <CellHead>id</CellHead>
                <CellHead>sent at</CellHead>
                <CellHead>sent by</CellHead>
                <CellHead>campaign id</CellHead>
                <CellHead>campaign title</CellHead>
            </RowHead>
            </thead>
            <tbody>
            {data.content.map(item => <WeeklyCampaignListItem key={item.id} item={item}/>)}
            </tbody>
        </Table>}

        <Pagination onPageChanged={handlePagination}
                    totalElements={pagination.totalElements}
                    pageLimit={pagination.size}/>
    </Section>;
};

WeeklyCampaignList.propTypes = {};

export default WeeklyCampaignList;
