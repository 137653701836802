import {atom, selector} from "recoil";

export const userAtom = atom({
    key: 'userAtom',
    default: ""
})

export const userSelector = selector({
    key: 'userSelector',
    get: ({get}) => get(userAtom)
})