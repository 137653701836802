import {Redirect, Route} from "react-router-dom";

export const PrivateRoute = ({component: Component, authenticated, ...rest}) =>
    <Route {...rest}
           render={props => authenticated
               ? <Component {...rest} {...props} />
               : <Redirect
                   to={{
                       pathname: '/login',
                       state: {from: props.location}
                   }}
               />
           }
    />;