export const colorScheme = {
    "primary": {
        "normal": "#1976d2",
        "light": "#63a4ff",
        "dark": "#004ba0",
        "color": "white"
    } ,
    "secondary": {
        "normal": "#7c4dff",
        "light": "#b47cff",
        "dark": "#3f1dcb",
        "color": "white"
    },
    "success": {
        "normal": "#0F9960",
        "light": "#53eeae",
        "dark": "#0a6b43",
        "color": "white"
    },
    "warning": {
        "normal": "#EB7E10",
        "light": "#f8ca9c",
        "dark": "#bb640d",
        "color": "white"
    },
    "danger": {
        "normal": "#db3737",
        "light": "#eea3a3",
        "dark": "#a71e1e",
        "color": "white"
    },
    "info": {
        "normal": "#137cbd",
        "light": "#bde2f8",
        "dark": "#0c4e77",
        "color": "white"
    },
    "alert": {
        "normal": "#5642a6",
        "light": "#c0b8e3",
        "dark": "#3a2c6f",
        "color": "white"
    },
    "system": {
        "normal": "#008075",
        "light": "#e6fffd",
        "dark": "#004d46",
        "color": "white"
    },
    "dark": {
        "normal": "#2e323f",
        "light": "#3C4152",
        "dark": "#2B2F3B",
        "color": "white"
    },
    "light": {
        "normal": "#e1e8ed",
        "light": "#f1f4f7",
        "dark": "#b1c3d0",
        "color": "black"
    },
}