import {useCallback, useReducer} from "react";

function reducer(state, action) {
    switch (action.type) {
        case 'LOADING':
            return {...state, loading: true};
        case 'ERROR':
            return {...state, loading: false, error: action.error};
        case 'DATA':
            return {...state, loading: false, data: action.data};
    }
}

function status(response) {
    if (!response.ok) return Promise.reject(response.text());
    return Promise.resolve(response);
}

function data(response) {
    const contentType = response.headers.get("content-type");
    console.log(contentType)
    return contentType && contentType === "application/json" ? response.json() : response.text();
}

const postFetch = async function (endpoint, data, token) {
    const headers = token ? new Headers({
        'content-type': 'application/json', 'Authorization': 'Bearer ' + token
    }) : new Headers({
        'content-type': 'application/json'
    })
    return apiFetch(endpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
}

const uploadFetch = async function (endpoint, data, token) {
    return await fetch(endpoint, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        }),
        body: data
    })
}

const deleteFetch = async function (endpoint, token) {
    const headers = token ? new Headers({
        'content-type': 'application/json', 'Authorization': 'Bearer ' + token
    }) : new Headers({
        'content-type': 'application/json'
    })
    return apiFetch(endpoint, {
        method: "DELETE",
        headers,
    })
};

const putFetch = async function (endpoint, data, token) {
    const headers = token ? new Headers({
        'content-type': 'application/json', 'Authorization': 'Bearer ' + token
    }) : new Headers({
        'content-type': 'application/json'
    })
    return apiFetch(endpoint, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
}

const apiFetch = async function (endpoint, options) {
    return await fetch(endpoint, options)
        .then(status)
        .then(data)
}

/**
 *
 * @returns {{data, loading, doFetch: (function(*=, *=): Promise<void>), errors}}
 */
const useDownloadFetch = () => {
    const [state, dispatch] = useReducer(reducer, {
        error: undefined,
        loading: false,
        data: undefined,
    });

    const doFetch = useCallback(async function (endpoint, options) {
        dispatch({type: 'LOADING'})
        let fileName = undefined;
        await fetch(endpoint, options)
            .then(status)
            .then(res => {
                fileName = res.headers.get('content-disposition').split("filename=")[1]
                return res.blob()
            })
            .then(data => {
                    data.fileName = fileName;
                    dispatch({type: 'DATA', data})
            })
            .catch(error => {
                try {
                    error.then(err => dispatch({type: 'ERROR', error: JSON.parse(err)}))
                } catch (e) {
                    dispatch({
                        type: 'ERROR', error: {
                            message: "Vous avez perdu la connexion avec le serveur.",
                            title: "Fetch error"
                        }
                    })
                }
            })
    }, []);

    return {
        error: state.error,
        loading: state.loading,
        data: state.data,
        doFetch
    }
}

/**
 *
 * @returns {{data, loading, doFetch: (function(*=, *=): Promise<void>), errors}}
 */
const useApiFetch = () => {
    const [state, dispatch] = useReducer(reducer, {
        error: undefined,
        loading: false,
        data: undefined,
    });

    const doFetch = useCallback(async function (endpoint, options) {
        dispatch({type: 'LOADING'})
        apiFetch(endpoint, options)
            .then(data => dispatch({type: 'DATA', data}))
            .catch(error => {
                try {
                    error.then(err => dispatch({type: 'ERROR', error: JSON.parse(err)}))
                } catch (e) {
                    dispatch({
                        type: 'ERROR', error: {
                            message: "Vous avez perdu la connexion avec le serveur.",
                            title: "Fetch error"
                        }
                    })
                }
            })
    }, []);

    return {
        error: state.error,
        loading: state.loading,
        data: state.data,
        doFetch
    }
}

export {useApiFetch, useDownloadFetch, apiFetch, postFetch, uploadFetch, deleteFetch, putFetch}



