import React, {useState} from 'react';
import {
    components,
    DropDownMenu,
    Logo,
    MegaMenu,
    MenuItem,
    space,
    ThemeSwitcher,
    TopBar,
    useCtrlKeyPress,
    useKeyPress
} from "@jetam/core";
import {LogoutCircle, ShieldUser} from "styled-icons/remix-line";
import styled from "styled-components";
import {ColorSwatch} from "styled-icons/heroicons-outline";
import {useRecoilValue} from "recoil";
import {userSelector} from "../recoil/user.state";
import {useHistory} from "react-router-dom";
import ExportMenu from "./ExportMenu";
import MailchimpMenu from "./MailchimpMenu";

const LightColorSwatch = styled(ColorSwatch)`
  color: ${props => props.theme.scheme.dark.normal};
  height: 1.1rem;
  width: 1.1rem;
`

const DarkColorSwatch = styled(ColorSwatch)`
  color: ${props => props.theme.scheme.light.normal};
  height: 1.1rem;
  width: 1.1rem;
`

const PushToRight = styled.div`
  flex-grow: 1;
`

const LogoStyled = styled(Logo)`
  height: ${props => props.theme.components.topBar.logo.height};
  width: auto;
  margin: ${space(1)};
`

const TopBarCustomized = props => {

    const [megaMenuOpened, setMegaMenuOpened] = useState(true);

    const user = useRecoilValue(userSelector);

    useCtrlKeyPress('m', () => setMegaMenuOpened(true));
    useKeyPress('Escape', () => setMegaMenuOpened(false));

    const handleRoute = route => {
        setMegaMenuOpened(false);
        history.push(route);
    }

    const history = useHistory();

    return <TopBar>
        <LogoStyled src={"https://k-rixe.com/wp-content/uploads/2020/09/LOGO_BLUE.png"}
                    onClick={() => setMegaMenuOpened(true)}/>
        <MegaMenu open={megaMenuOpened}
                  title={"Navigation"}
                  top={components.topBar.height}
                  menus={[
                      <MailchimpMenu handleRouteCallback={handleRoute}/>,
                      <ExportMenu handleRouteCallback={handleRoute}/>,
                  ]}
                  onClose={() => setMegaMenuOpened(false)}/>
        <PushToRight/>
        <DropDownMenu label={user.fullName} icon={<ShieldUser/>} items={[
            <MenuItem icon={<LogoutCircle/>} onClick={() => history.push("/logout")}>Se déconnecter</MenuItem>
        ]}/>
        <ThemeSwitcher lightIcon={<LightColorSwatch/>} darkIcon={<DarkColorSwatch/>}/>
    </TopBar>;
};

TopBarCustomized.propTypes = {};

export default TopBarCustomized;
