import React, {useEffect, useState} from 'react';
import {
    Card,
    CellHead,
    Flex,
    GrowElement,
    H1,
    Loader,
    Pagination,
    RowHead,
    Section,
    Table,
    useApiFetch,
    useLocalStorage,
    useSnackBar,
    Variant
} from "@jetam/core";
import {API, fetchParams, LOCAL_STORAGE_KEY} from "../../config/config";
import ExportContractButton from "../components/ExportContractButton";
import ExportContractListItem from "../components/ExportContractListItem";

const ExportContractList = props => {

    const [{token}] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const {data, loading, doFetch, error} = useApiFetch();
    const [pagination, setPagination] = useState({
        page: 0,
        size: 10,
        totalElements: 0
    });
    const addNotification = useSnackBar();

    useEffect(() => {
        console.log(data)
        if (data) {
            setPagination({
                totalElements: data.totalElements,
                page: data.number,
                size: data.size,
            })
        }
    }, [data])

    useEffect(() => {
        if (error) addNotification(error.message, Variant.DANGER)
    }, [error])

    const handlePagination = page =>
        doFetch(`${API.ENDPOINT}export/contract?page=${page.currentPage - 1}&size=${page.pageLimit}`, fetchParams("GET", token));

    const handleOnSuccess = e => {
        handlePagination({
            currentPage: pagination.page + 1,
            pageLimit: pagination.size,
        })
    }

    return <Section>
        <Flex>
            <GrowElement level={1}>
                <H1>Derniers exports des contrats</H1>
            </GrowElement>
            <ExportContractButton onSuccess={handleOnSuccess}/>
        </Flex>

        {!data?.content && <Card><p>Data not found</p></Card>}
        {loading && <Loader/>}
        {data?.content && !loading && <Table>
            <thead>
            <RowHead>
                <CellHead>id</CellHead>
                <CellHead>created at</CellHead>
                <CellHead>by</CellHead>
                <CellHead>contracts</CellHead>
                <CellHead>filename</CellHead>
            </RowHead>
            </thead>
            <tbody>
            {data.content.map(item => <ExportContractListItem key={item.id} item={item}/>)}
            </tbody>
        </Table>}

        <Pagination onPageChanged={handlePagination}
                    totalElements={pagination.totalElements}
                    pageLimit={pagination.size}/>
    </Section>;
};

ExportContractList.propTypes = {};

export default ExportContractList;
