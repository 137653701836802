import React, {useEffect, useState} from 'react';
import {Alert, Flex, Loader, Modal, TextButton, useApiFetch, useLocalStorage, useSnackBar, Variant} from "@jetam/core";
import {API, fetchParams, LOCAL_STORAGE_KEY} from "../../config/config";
import PropTypes from "prop-types";

const ConfirmModal = ({isOpened, onClose}) => {

    const {loading, doFetch, error, data} = useApiFetch();
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [{token}] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const addNotification = useSnackBar();

    const handleSubmit = () => {
        setErrorMessage(undefined);
        doFetch(`${API.ENDPOINT}mailchimp`, fetchParams("GET", token));
    }

    useEffect(() => setErrorMessage(error), [error]);

    useEffect(() => {
        if (data === "success") {
            onClose(true);
            addNotification("Campagne lancée avec succès.", Variant.SUCCESS)
        }
    }, [data]);

    return <Modal isOpened={isOpened}
                  variant={Variant.PRIMARY}
                  onClose={() => onClose(false)}
                  title={"Veuillez confirmer votre demande !"}>
        {errorMessage && <Alert title={errorMessage.title} variant={Variant.DANGER}>{errorMessage.message}</Alert>}

        {loading ? <Loader text={"Envoi de la campagne ..."}/> : <>
            <p>
                Confirmez-vous vouloir envoyer la campagne <strong>Réservation hebdomadaire</strong> pour une nouvelle
                semaine ?
            </p>
            <Flex align={"right"}>
                <TextButton onClick={() => onClose(false)}>Non</TextButton>
                <TextButton variant={Variant.DANGER} onClick={handleSubmit}>Oui</TextButton>
            </Flex>
        </>}

    </Modal>;
};

ConfirmModal.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ConfirmModal;
