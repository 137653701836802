import React from 'react';
import PropTypes from 'prop-types';
import {Cell, Pin, Row} from "@jetam/core";
import moment from "moment";

const WeeklyCampaignListItem = ({ item }) => {
    return <Row>
        <Cell>{item.id}</Cell>
        <Cell>{moment(item.sentAt).format("DD/MM/YYYY hh:mm:ss")}</Cell>
        <Cell><Pin>{item.createdBy}</Pin></Cell>
        <Cell>{item.campaignId}</Cell>
        <Cell>{item.campaignTitle}</Cell>
    </Row>;
};

WeeklyCampaignListItem.propTypes = {
    item: PropTypes.object.isRequired
};

export default WeeklyCampaignListItem;
