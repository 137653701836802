import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";
import AuthRouter from "./auth/AuthRouter";
import {ColorSchemeContext, IconContext} from "@jetam/core";
import {colorScheme} from "./theme/ColorScheme";
import {QuestionCircle} from "styled-icons/fa-regular";
import {Electron} from "styled-icons/simple-icons";
import {Bug, Check, Error, HelpCircle, InfoCircle, MessageDetail, MessageDots} from "styled-icons/boxicons-regular";
import {Close} from "styled-icons/material";

const icons = {
    "primary": <MessageDetail/>,
    "secondary": <MessageDots/>,
    "info": <InfoCircle/>,
    "warning": <QuestionCircle/>,
    "alert": <Bug/>,
    "danger": <Error/>,
    "success": <Check/>,
    "system": <Electron/>,
    "dark": <HelpCircle/>,
    "light": <HelpCircle/>,
    "close": <Close/>
}

const App = () => <RecoilRoot>
    <ColorSchemeContext.Provider value={colorScheme}>
        <IconContext.Provider value={icons}>
            <BrowserRouter basename='/'>
                <AuthRouter/>
            </BrowserRouter>
        </IconContext.Provider>
    </ColorSchemeContext.Provider>
</RecoilRoot>;

export default App;
