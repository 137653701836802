import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Cell, HyperLink, Pin, Row, useLocalStorage, useSnackBar, Variant} from "@jetam/core";
import moment from "moment";
import {API, fetchParams, LOCAL_STORAGE_KEY} from "../../config/config";
import {useDownloadFetch} from "@jetam/core/src/hooks";
import styled from "styled-components";

const LinkCustom = styled(HyperLink)`
  &:hover {
	cursor: pointer;
  }
`

const ExportContractListItem = ({item}) => {

    const [{token}] = useLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    const {data, loading, doFetch, error} = useDownloadFetch();
    const addNotification = useSnackBar();

    useEffect(() => {
        if (data) {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement("a");
            a.href = url;
            a.download = data.fileName;
            a.click();
            addNotification("Fichier téléchargé avec succès.", Variant.SUCCESS)
        }
    }, [data])

    useEffect(() => {
        if (error) addNotification(error.message, Variant.DANGER)
    }, [error])

    const handleNewExport = () => doFetch(`${API.ENDPOINT}export/contract/download/${item.id}`, fetchParams("GET", token))

    return <Row>
        <Cell>{item.id}</Cell>
        <Cell>{moment(item.createdAt).format("DD/MM/YYYY hh:mm:ss")}</Cell>
        <Cell><Pin>{item.createdBy}</Pin></Cell>
        <Cell>{item.contractSize}</Cell>
        <Cell><LinkCustom onClick={handleNewExport}>{item.filename}</LinkCustom></Cell>
    </Row>;
};

ExportContractListItem.propTypes = {
    item: PropTypes.object.isRequired
};

export default ExportContractListItem;
