import React from 'react';
import PropTypes from 'prop-types';
import {Menu, MenuItem, Variant} from "@jetam/core";
import {SendClock} from "styled-icons/fluentui-system-regular";

const MailchimpMenu = ({handleRouteCallback}) =>
    <Menu variant={Variant.SECONDARY} title={"Mailchimp"}
          items={[
              <MenuItem label={"Campagne hebdomadaire"}
                        icon={<SendClock/>}
                        onClick={() => handleRouteCallback("/mailchimp/weekly")}/>,
          ]}/>;

MailchimpMenu.propTypes = {
    handleRouteCallback: PropTypes.func.isRequired
};

export default MailchimpMenu;
