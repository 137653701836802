import React from 'react';
import PropTypes from 'prop-types';
import {SnackBarProvider, ThemeContainer, ThemeStore} from "@jetam/core";
import PreLoader from "./PreLoader";
import {createGlobalStyle} from "styled-components";


const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

  body {
	font-family: 'Open Sans', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
	font-family: Inter, sans-serif;
  }
`

const Main = props => {
    return <ThemeStore>
        <ThemeContainer>
            <GlobalStyle/>
            <SnackBarProvider duration={3000} displayElements={10} bottom right>
                <PreLoader/>
            </SnackBarProvider>
        </ThemeContainer>
    </ThemeStore>;
};

Main.propTypes = {};

export default Main;
